<template>
  <div
    class="bg-indigo-200 dark:bg-indigo-600 dark:text-white rounded-lg px-4 pb-8"
  >
    <h1>코딩자습서</h1>
    <div class="pl-4">
      <div>
        <a
          href="https://www.youtube.com/channel/UCj8eNn2MxSUB1wf5y6FR1WQ"
          target="_blank"
        >
          LINK</a
        >
      </div>
      <div>
        열공후 영상으로 만들어올립니다. 클레이튼과 vue 에 집중하고 있습니다.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("doum/coding_tutorial mounted");
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
};
</script>

<style scoped></style>
